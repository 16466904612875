.uestra-text-alignleft {
    text-align: left;
}

.uestra-text-aligncenter {
    text-align: center;
}

.uestra-text-alignright {
    text-align: right;
}

.uestra-text-alignjustify {
    text-align: justify;
}

.uestra-text-indent {
    margin-left: 40px;
}

.has-blau-1-color {
    color: #1D89BF;
}

.has-blau-2-color {
    color: #2693FF;
}

.has-blau-3-color {
    color: #1F4ACC;
}

.has-rot-1-color {
    color: #E66000;
}

.has-rot-2-color {
    color: #CC2200;
}

.has-rot-3-color {
    color: #B3002D;
}

.has-gruen-1-color {
    color: #66991A;
}

.has-gruen-2-color {
    color: #338C15;
}

.has-gruen-3-color {
    color: #50730B;
}

.has-violet-color {
    color: #78298C;
}

.has-gelb-color {
    color: #CC8800;
}

.has-grau-color {
    color: #191919;
}
