@font-face {
     font-family: "DINforPBR";
     font-style: normal;
     font-weight: 900;
     src: url("fonts/DINforPBRW03-Black.eot?#iefix");
     src: url("fonts/DINforPBRW03-Black.eot?#iefix") format("eot"), url("fonts/DINforPBRW03-Black.woff2") format("woff2"), url("fonts/DINforPBRW03-Black.woff") format("woff"), url("fonts/DINforPBRW03-Black.ttf") format("truetype");
 }

 @font-face {
     font-family: "DINforPBR";
     font-style: normal;
     font-weight: 500;
     src: url("fonts/DINforPBRW03-Medium.eot?#iefix");
     src: url("fonts/DINforPBRW03-Medium.eot?#iefix") format("eot"), url("fonts/DINforPBRW03-Medium.woff2") format("woff2"), url("fonts/DINforPBRW03-Medium.woff") format("woff"), url("fonts/DINforPBRW03-Medium.ttf") format("truetype");
 }

 @font-face {
     font-family: "DINforPBR";
     font-style: normal;
     font-weight: 400;
     src: url("fonts/DINforPBRW03-Regular.eot?#iefix");
     src: url("fonts/DINforPBRW03-Regular.eot?#iefix") format("eot"), url("fonts/DINforPBRW03-Regular.woff2") format("woff2"), url("fonts/DINforPBRW03-Regular.woff") format("woff"), url("fonts/DINforPBRW03-Regular.ttf") format("truetype");
 }
